var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fill-height purple-gradient"},[_c('Navbar'),_c('UserNavbar'),_c('v-container',{staticClass:"px-5 px-sm-9"},[_c('SearchBar')],1)],1),_c('div',{staticClass:"body-container",style:([
      _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? { 'padding-bottom': '30px' }
        : { 'padding-bottom': '70px' } ])},[_c('v-container',{staticClass:"px-10 px-md-auto"},[_c('div',[_c('div',{staticClass:"mx-0 mb-1 text-h2 text-md-h1 font-weight-bold"},[_vm._v(" Paquetes Zaturna ")]),_c('div',{staticClass:"flex-row text-subtitle-1 text-sm-h4 font-weight-regular mb-4 ml-md-1",class:_vm.loadingFirstPackages && 'mb-16 mb-sm-12 pb-2 pb-sm-0'},[(!_vm.loadingFirstPackages)?_c('div',[_c('span',{staticClass:"d-flex d-sm-inline-flex"},[_vm._v(" "+_vm._s(_vm.packagesQuantity !== 1 ? _vm.packagesQuantity + " paquetes " + "publicados" : _vm.packagesQuantity + " paquete " + "publicado")+" ")]),_c('span',{staticClass:"d-flex d-sm-inline-flex mx-sm-8"},[_c('v-icon',{staticClass:"mr-2 secondary--text",attrs:{"small":""}},[_vm._v("fa-map-pin")]),_vm._v(" "+_vm._s(_vm.citiesQuantity != 1 ? _vm.citiesQuantity + " ciudades" : _vm.citiesQuantity + " ciudad")+" ")],1)]):_vm._e()])]),_c('v-divider',{staticClass:"mx-n2 secondary"}),(
          !_vm.loadingFirstPackages &&
          !_vm.loadingMorePackages &&
          _vm.packagesQuantity === 0
        )?_c('div',{staticClass:"my-16 text-center descent--text text--darken-2 text-h4 font-weight-regular"},[_vm._v(" No se han encontrado paquetes ")]):_vm._e()],1),(_vm.packagesQuantity !== 0)?_c('InfiniteListCards',{attrs:{"cards":_vm.packages,"no-more-cards":_vm.noMorePackages,"loading-first-cards":_vm.loadingFirstPackages,"loading-more-cards":_vm.loadingMorePackages,"type":"Package"},on:{"handleMoreCards":_vm.fetchMorePackages}}):_vm._e(),(_vm.loadingFirstPackages || _vm.loadingMorePackages)?_c('v-container',{staticClass:"d-flex flex-column align-center my-10 my-sm-12"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":100}})],1):_vm._e()],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }